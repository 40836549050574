import { useEffect, useState } from 'react';
import { Layout, Menu, Select, Input, Button, notification, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import PasteService from '../Services/PasteService';
import { instanceOfErrorReponse } from '../Utils/guardChecking';
import ReCaptcha from '../Interfaces/ReCaptcha';

const { Header, Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

declare global {
  interface Window {
    captchaOnLoad: () => void
    grecaptcha: ReCaptcha
  }
}

const layoutStyle = {
  background: 'rgb(43, 43, 43)',
  color: 'white'
}

const CreatePaste = () => {
  const history = useHistory();

  const [submit, setSubmit] = useState(false);
  const [content, setContent] = useState('');
  const [language, setLanguage] = useState('');
  const [allowedLanguages, setAllowedLanguages] = useState(['']);

  useEffect(() => {
    PasteService.getLanguages().then((data) => {
      if (instanceOfErrorReponse(data)) {
        return notification.error({
          message: 'Error',
          description: data.error
        });
      }

      setAllowedLanguages(data);
    })
  }, []);

  const submitPaste = (content: string, language: string) => {
    if (content === '') {
      return notification.error({
        message: 'Error',
        description: 'Please enter content for a paste'
      });
    }

    if (language === '') {
      return notification.error({
        message: 'Error',
        description: 'Please select a language'
      });
    }

    setSubmit(true);

    window.grecaptcha.ready(function () {
      window.grecaptcha.execute('6LebE6saAAAAAKncxGWTxbNOlh3cfbaZkOLAXLN1', { action: 'submit' }).then(function (token) {
        PasteService.createPaste(content, language, token).then((data) => {
          if (!instanceOfErrorReponse(data)) {
            return history.push(`/${data?.key}`);
          }

          setSubmit(false);

          notification.error({
            message: 'Error',
            description: data.error
          });
        });
      }).catch(() => {
        setSubmit(false);

        notification.error({
          message: 'Error',
          description: 'An unknown error has occured'
        });
      });
    });
  };

  return (
    <Layout style={layoutStyle}>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <Menu mode="horizontal" style={{ float: 'right', borderBottom: 'none', background: '#1f1f1f' }} selectable={false}>
          <Menu.Item style={{ padding: 0, marginRight: 5 }}>
            <Spin spinning={allowedLanguages === ['']} delay={500}>
              <Select placeholder='Select Language...' style={{ width: 200 }} showSearch onChange={(value: string) => { setLanguage(value) }}>
                {allowedLanguages.map((language) => {
                  return <Option key={language} value={language}>{language}</Option>
                })}
              </Select>
            </Spin>
          </Menu.Item>
          <Menu.Item style={{ padding: 0, marginLeft: 5 }}>
            <Spin spinning={submit} delay={500}>
              <Button onClick={() => { submitPaste(content, language); }}>Save</Button>
            </Spin>
          </Menu.Item>
        </Menu>
      </Header>
      <Content className="site-layout" style={{ marginTop: 64 }}>
        <div className="site-layout-background" style={{ padding: 24, height: '100vh' }}>
          <TextArea style={{ height: '100%', color: 'white' }} bordered={false} onChange={(value) => { setContent(value.currentTarget.value) }}></TextArea>
        </div>
      </Content>
    </Layout>
  );
}

export default CreatePaste;