import PasteResponse from '../Interfaces/PasteResponse';
import ErrorResponse from '../Interfaces/ErrorResponse';
import CreatePasteResponse from '../Interfaces/CreatePasteResponse';

class PasteService {
    static API_URL: string = "https://paste-api.dictu-lang.com";

    /**
     * Get paste content and language based on given ID
     * 
     * @param id string
     * @returns Promise<PasteResponse | ErrorResponse>
     */
    static async getPaste(id: string): Promise<PasteResponse | ErrorResponse> {
        try {
            const result = await fetch(`${PasteService.API_URL}?key=${id}`);
            const json = await result.json();

            if (result.status === 200) {
                return json;
            }

            return { status: result.status, error: json.message };
        } catch (error) {
            return { status: 500, error: 'An unknown error has occured' };
        }
    }

    /**
     * Create a new paste given content and a language
     * 
     * @param content string
     * @param language string
     * @returns Promise<CreatePasteResponse | ErrorResponse>
     */
    static async createPaste(content: string, language: string, token: string): Promise<CreatePasteResponse | ErrorResponse> {
        try {
            const result = await fetch(`${PasteService.API_URL}`, {
                method: 'POST',
                body: JSON.stringify({ content, language, token }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await result.json();

            if (result.status === 200) {
                return json;
            }

            return { status: result.status, error: json.message };
        } catch (error) {
            return { status: 500, error: 'An unknown error has occured' };
        }
    }

    static async getLanguages(): Promise<string[] | ErrorResponse> {
        try {
            const result = await fetch(`${PasteService.API_URL}/languages`);
            return await result.json();
        } catch (error) {
            return { status: 500, error: 'An unknown error has occured' };
        }
    }
}

export default PasteService;