import { useEffect, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import PasteService from '../Services/PasteService';
import PasteResponse from '../Interfaces/PasteResponse';
import ErrorResponse from '../Interfaces/ErrorResponse';
import { instanceOfErrorReponse } from '../Utils/guardChecking';
import { Layout, Menu } from 'antd';
import { Content } from 'antd/lib/layout/layout';

const { Header } = Layout;

const PasteComponent = ({ content, language = '' }: { content: string, language?: string }) => {
  return (
    <Layout style={{ height: '100vh' }}>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <Menu mode="horizontal" style={{ float: 'right', borderBottom: 'none', background: '#1f1f1f' }} selectable={false}>
          <Menu.Item style={{ padding: 0, marginLeft: 10 }}>
            <a href="/">
              Back
            </a>
          </Menu.Item>
        </Menu>
      </Header>
      <Content className="site-layout" style={{ marginTop: 64 }}>
        <SyntaxHighlighter style={darcula} customStyle={{ height: '100%', margin: 0 }} showLineNumbers={true} language={language}>{content}</SyntaxHighlighter>
      </Content>
    </Layout>
  );
}

const Paste = (props: any) => {
  const [paste, setPaste] = useState<PasteResponse | ErrorResponse | undefined>(undefined);
  const { key } = props.match.params;

  useEffect(() => {
    PasteService.getPaste(key).then(
      (data) => {
        setPaste(data)
      }
    );
  }, [key]);

  if (paste === undefined) {
    return (
      <PasteComponent content='Loading'></PasteComponent>
    );
  }

  if (instanceOfErrorReponse(paste)) {
    return (
      <PasteComponent content={paste.error}></PasteComponent>
    );
  }

  return (
    <PasteComponent content={paste.content} language={paste.language}></PasteComponent>
  );
};

export default Paste;
